<template>
  <div class="order-online">
    <div class="order-header" :style="{'background':'url(' + $http + '/img/videoTopBack.png)'}">
      <span>Online Orders</span>
      <img src="@/assets/zh/orderOnline/closeIcon.png" @click="closeOrderOnline" alt="" />
    </div>
    <div class="order-body">
      <!-- 订单信息 -->
      <div class="order-table">
        <img
          class="order-title-img"
          src="@/assets/en/orderOnline/orderInfo.png"
          alt="订单信息"
        />
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{
            'text-align': 'center',
            'border-bottom-color': '#eac596',
            'font-size': '1.25rem',
            'color': '#000',
            'font-family': 'PingFang SC',
          }"
          :row-style="{
            height: '5.625rem',
            'line-height': '5.625rem'
          }"
          :cell-style='changeCellStyle'
        >
          <el-table-column prop="productName" label="Product" align="center"></el-table-column>
          <el-table-column prop="abroadOriginalPrice" label="Price" align="center">
            <template slot-scope="scope"> $ {{ scope.row.abroadOriginalPrice }} </template>
          </el-table-column>
          <el-table-column label="Quantity" align="center">
            <template slot-scope="scope">
              <div class="custom-outside">
                <div class="custom-count">
                  <img
                    v-if="scope.row.count == 0"
                    src="@/assets/zh/orderOnline/minusDisable.png"
                    alt=""
                  />
                  <img
                    v-if="scope.row.count > 0"
                    src="@/assets/zh/orderOnline/minusEnable.png"
                    @click="resetCountFunc('minus', scope.$index)"
                    alt=""
                  />
                  <input
                    @input="countSet(scope.$index)"
                    v-model="scope.row.count"
                  />
                  <img
                    v-if="scope.row.count == max"
                    src="@/assets/zh/orderOnline/addDisable.png"
                    alt=""
                  />
                  <img
                    v-if="scope.row.count < max"
                    src="@/assets/zh/orderOnline/addEnable.png"
                    @click="resetCountFunc('add', scope.$index)"
                    alt=""
                  />
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="preferentialMode"
            label="Offer method"
            align="center"
          ></el-table-column>
          <el-table-column label="Total" align="center">
            <template slot-scope="scope">
              <span
                style="
                  color: #ff0a0a;
                  font-weight: 500;
                  font-family: PingFang SC;
                  font-size: 1.875rem;
                "
                >$ {{ scope.row.abroadOriginalPrice * scope.row.count }}</span
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 联系方式 -->
      <div class="order-connect">
         <img
          class="order-title-img"
          src="@/assets/en/orderOnline/connectWay.png"
          alt="联系方式"
        />
        <div style="margin-left: 60px">
          <!-- 邮箱 -->
          <div class="connect-form">
            <div class="form-label">
              <span>*</span>
              Email：
            </div>
            <div class="form-value">
              <el-input
                v-model="connectForm.email"
                placeholder="Please enter the correct mailbox stop, used to verify the mailbox, required"
                class="email-input"
                @input="verifyEmail"
              ></el-input>
            </div>
            <div v-if="sendEmail==0" class="sure-email" @click="sendEmailConfirm">Confirm email</div>
            <div v-else-if="sendEmail==1" class="confirmation-email">
              <i class="el-icon-time"></i>
              <span>Email confirmation... {{time}}s</span>
            </div>
            <div v-else-if="sendEmail==2" class="finisn-email">
              <img src="../../assets/img/rightIcon.png" alt="">
              <span>Email verified</span>
            </div>
          </div>
          <!-- 邮箱校验 -->
          <div class="connect-form">
            <div class="form-label"></div>
            <div
              class="form-value form-empty-tips form-tips"
              v-if="emailStatus === 'empty'"
            >
              <img src="@/assets/zh/orderOnline/tipsIcon.png" alt="" />
              <div>
                Tips: Email will become the main contact method for product download and product serial number Lcense, please confirm the email before proceeding to the next step.
              </div>
            </div>
            <div
              class="form-value form-success-tips form-tips"
              v-if="emailStatus === 'success'"
            >
              <img src="@/assets/zh/orderOnline/tipsSuccessIcon.png" alt="" />
              <div>
                Your email address is correct, please fill in your name, click "confirm email", your email will receive a confirmation email, click to confirm can continue to purchase.
              </div>
            </div>
            <div
              class="form-value form-error-tips form-tips"
              v-if="emailStatus === 'error'"
            >
              <img src="@/assets/zh/orderOnline/tipsErrorIcon.png" alt="" />
              <div>Please enter the correct email address</div>
            </div>
          </div>
          <!-- 用户名 -->
          <div class="connect-form">
            <div class="form-label">
              <span>*</span>
              First name：
            </div>
            <div class="form-value">
              <el-input
                v-model="connectForm.realName"
                placeholder="Enter name clearly, required"
                class="same-input"
              ></el-input>
            </div>
          </div>
          <!-- 联系电话 -->
          <!-- <div class="connect-form">
            <div class="form-label">Phone number：</div>
            <div class="form-value">
              <el-input
                v-model="connectForm.mobile"
                placeholder="Clear enter the phone number"
                class="same-input"
                @input="verifyPhone"
              ></el-input>
            </div>
          </div> -->
          <!-- 电话校验 -->
          <!-- <div class="connect-form">
            <div class="form-label"></div>
            <div
              class="form-value form-error-tips form-tips form-tips-mobile"
              style="width: 368px;"
              v-if="phoneStatus === 'error'"
            >
              <img src="@/assets/zh/orderOnline/tipsErrorIcon.png" alt="" />
              <div>Please enter a valid mobile phone number</div>
            </div>
          </div> -->
        </div>
      </div>
      <!-- 支付结果 -->
      <div class="pay-result">
        <img
            class="order-title-img"
            src="@/assets/en/orderOnline/payWay.png"
            alt="支付方式"
        />
        <div class="pay-way" v-if="!$route.query.tradeNo">
          <div v-for="(item,i) in payList" :key="i" :class="{'pay-select':true, 'pay-active': i==selectIndex}" @click="payClick(item,i)">
            <img class="pay-icon" v-if="i==selectIndex" src="@/assets/en/orderOnline/select.png" alt="">
            <img class="pay-icon" v-else src="@/assets/en/orderOnline/kong.png" alt="">
            <img class="pay-image" :src="item.imgurl" alt="">
            <span class="pay-label">{{item.label}}</span>
          </div>
          <div class="pay-sure" v-if="sendEmail!=2">Confirm payment</div>
          <div class="pay-sure-none" @click="addOrder" v-if="sendEmail==2">Confirm payment</div>
          <div class="pay-tip">
              <img src="@/assets/zh/orderOnline/tipsIcon.png" alt="" />
              <div>
                Tips: Please complete the payment with in 48 hours. After successful payment, we will send the product download link and product serial number License to your email!
              </div>
            </div>
        </div>
      </div>
      <div class="pay-confirm">
        <div class="get-order" v-if="payStatus != 'SUCCESS' && outPayStatus != 2 && $route.query.token && payMethod==null" @click="checkOutStatus">Confirm payment</div>
      </div>
      <div class="pay-result" v-if="payStatus == 'SUCCESS' || outPayStatus == 2">
        <div class="result-info">
            <div class="result-img">
                <img :src="$http + '/zh/orderOnline/paySuccessResult.png'" alt="">
                <span>Payment success!</span>
            </div>
            <div class="result-text">
                <div>dear {{connectForm.realName}} user:</div>
                <div>Hello!</div>
                <div>You have paid successfully, please check the product download link and serial number License in email.</div>
            </div>
        </div>
      </div>
       <div class="pay-result" v-if="payStatus == 'INITIAL' && payMethod == 3">
        <div class="result-info">
            <div class="result-img">
                <img :src="$http + '/zh/orderOnline/paySuccessResult.png'" alt="">
                <span>Payment success!</span>
            </div>
            <div class="result-text">
                <div>dear {{connectForm.realName}} user:</div>
                <div>Hello!</div>
                <div>We will deliver the goods after we receive the remittance.</div>
            </div>
        </div>
      </div>
      <div class="pay-result" v-if="payStatus == 'FAIL'">
        <div class="result-info result-fail">
            <div class="result-img">
                <img :src="$http + '/zh/orderOnline/payFailResult.png'" alt="">
                <span>Payment failure!</span>
            </div>
            <div class="result-text">
                <div>dear {{connectForm.realName}} user:</div>
                <div>Hello!</div>
                <div>There is a problem with your payment, please pay again</div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { productInfoGet, sendEmailConfirm, getEmailConfirm, addOrder, comfirmOrder, getCounterUrl, getAuthorizeOrder, productInfoItems, productInfoQuery, payResult } from '@/api/order'
export default {
  data() {
    return {
      max: 10, // 购买最大值
      // 可购买的产品
      tableData: [],
      //   联系方式表单
      connectForm: {
        email: "", // 邮箱
        realName: "", // 用户名
        // mobile: "", // 联系电话
      },
      emailStatus: "empty", // 判断邮箱是否正确 empty: 未输入 success: 正确 error: 错误
      phoneStatus: "empty", // 判断手机号是否正确 empty/success都不展示。仅为error展示
      // 支付方式
      payList: [
        { label: 'Visa', imgurl: require('@/assets/en/orderOnline/visa.png') },
        { label: 'Paypal', imgurl: require('@/assets/en/orderOnline/paypal.png') },
        { label: 'Alipay', imgurl: require('@/assets/en/orderOnline/alipay.png') },
        { label: 'WeChat', imgurl: require('@/assets/en/orderOnline/weChat.png') },
      ],
      selectIndex: 0, // 选中
      payStatus: 'INITIAL', // 国内支付状态 INITIAL： 等待  SUCCESS： 成功  FAIL： 失败
      outPayStatus: 1, // 国外订单支付状态 1：待支付  2： 已支付
      payMethod: null, // 支付方式 5 paypal
      isFirst: true, // 第一次执行信息查询
      keepTime: 0, // 支付状态查询
      sendEmail: 0, // 邮箱校验的状态 0 未发送 1 校验中 2 已校验
      timer: null, // 邮箱校验的定时器
      time: 180, // 邮箱校验时间
      throttleTime: 0, // 节流时间
      throttleTimer: '', // 节流定时器 
    };
  },
  mounted(){
    this.getProductInfo()
    this.keepTime = 60
  },
  methods: {
    // el-table自定义样式
    changeCellStyle(row){
      if(row.column.label === 'productName'){
        return "paddingLeft:2.0625rem"
      }else{
        return ""
      }
    },
    // 获取产品列表
    getProductInfo(){
      productInfoGet({id: 1}).then(res=>{
        res.data.list.forEach(e=>{
          e.count = 1
          e.preferentialMode = 'Winter sale'
          e.defaultPrice = '1388'
        })
        this.tableData = res.data.list
        // 如果是支付（不管成功还是失败）之后跳转回来查询支付结果
        if(this.$route.query.tradeNo && !this.$route.query.token){ // 卡拉卡支付
          this.payResultFunc()
        }else{ // 国外支付
          this.getProductsItem(this.$route.query.orderId)
          this.getProductsOrder(this.$route.query.orderId)
        }
      })
    },
    // 国外支付结果查询
    checkOutStatus(){
      // 节流
      if(!this.throttleFunc()){
        return
      }
      getAuthorizeOrder({
        orderId: this.$route.query.token
      }).then(res=>{
        this.keepTime = 60
        this.outPayResult()
      })
    },
    // 点击确认支付循环查询支付状态--国外支付
    outPayResult(){
      payResult({
          tradeNo: this.$route.query.tradeNo
        }).then(res=>{
          this.payStatus = res.data.payStatus // 查询状态
          // 查询60秒，超时停止查询
          if(this.keepTime<=0){
            return
          }
          // 如果没查询到结果，继续查询
          if(res.data.payStatus=="INITIAL"){
            this.keepTime--
            this.payMethod = res.data.payMethod // 支付方式
            this.outPayResult()
          }
        })
    },
    // 获取查询结果 -- 卡拉卡支付
    payResultFunc(){
      // 查询支付结果
      payResult({
        tradeNo: this.$route.query.tradeNo
      }).then(res=>{
        // 仅在此一次查询执行
        if(this.isFirst){
          this.getProductsItem(res.data.orderNo)
          this.getProductsOrder(res.data.orderNo)
        }
        this.isFirst = false // 是否为第一次查询
        this.payStatus = res.data.payStatus // 查询状态
        // 查询60秒，超时停止查询
        if(this.keepTime<=0){
          return
        }
        // 如果没查询到结果，继续查询
        if(res.data.payStatus=="INITIAL"){
          this.keepTime--
          this.payResultFunc()
        }
      })
    },
    // 获取产品产品填写的数量
    getProductsItem(id){
      productInfoItems({
        id
      }).then(res=>{
        res.data.list.forEach(_=>{
          let Index = this.tableData.findIndex(e=>e.id == _.productId) // 判断产品的产品id是否包含在列表之内
          // 如果产品与列表的id一致说明该产品已经被购买了，将产品数量添加至列表内
          if(Index!==-1){
            this.tableData[Index].count = _.amount
          }
        })
      })
    },
    // 获取订单信息， 获取用户名、邮箱、手机号信息
    getProductsOrder(id){
      productInfoQuery({
        id,
      }).then(_=>{
        let orderInfo = _.data.list[0] ? _.data.list[0] : {}
        if(orderInfo.email){
          this.connectForm.email = orderInfo.email
          this.connectForm.realName = orderInfo.realName
          this.connectForm.mobile = orderInfo.mobile
          this.outPayStatus = orderInfo.status // 支付状态
        }
      })
    },
    // 节流方法
    throttleFunc(){
      if(this.throttleTime == 0){
        this.throttleTime = 2
        this.throttleTimer = setInterval(() => {
          this.throttleTime--
          if(this.throttleTime == 0){
            clearInterval(this.throttleTimer)
          }
        }, 1000);
        return true
      }else{
        return false
      }
    },
    // 提交邮件
    sendEmailConfirm(){
      // 节流
      if(!this.throttleFunc()){
        return
      }
      if(this.$route.query.tradeNo){
        return
      }
      if(this.emailStatus === 'empty'){
        this.$message.warning('Please enter the email address and proceed to the next step.')
        return
      }
      if(this.emailStatus === 'error'){
        this.$message.warning('The current mailbox format is incorrect. Please check the mailbox carefully before proceeding to the next step.')
        return
      }
      if(!this.connectForm.realName){
        this.$message.warning('Please enter your name to proceed to the next step.')
        return
      }
      sendEmailConfirm({
        realName: this.connectForm.realName,
        email: this.connectForm.email
      }).then(res=>{
        this.$message.success('Sent successfully')
        this.time = 180
        this.sendEmail = 1 // 状态改为验证中
        this.timer = setInterval(() => {
          this.time--
          // 如果180s内没有验证邮箱。清空定时器，状态改为发送邮箱
          if(this.time === 0){
            clearInterval(this.timer)
            this.sendEmail = 0
            return
          }
          // 获取验证状态/如果data == 2说明已经验证过邮箱。清空定时器，状态改为已验证邮箱
          getEmailConfirm({
            email: this.connectForm.email
          }).then(res=>{
            if(res.data == '2'){
              clearInterval(this.timer)
              this.sendEmail = 2
            }
          })
        }, 1000);
      })
    },
    // 添加订单
    addOrder(){
      if(!this.throttleFunc()){
        return
      }
      if(this.selectIndex == 0){
        this.$message.warning('visa payment is not currently supported')
        return
      }
      let productItemList = this.tableData.map(_=>{
        return {
          productId: _.id,
          amount: _.count
        }
      })
      if(!productItemList.every(_=>_.amount>0)){
        this.$message.warning('Please select at least one product after submitting your order.')
        return
      }
      addOrder({
        realName: this.connectForm.realName,
        email: this.connectForm.email,
        callbackUrl: window.location.href.indexOf('?') === -1 ? window.location.href : window.location.href.split('?')[0],
        productItemList,
        sourceType: 2,
        payMethod: ['','2','1','1'][this.selectIndex]
      }).then(res=>{
        this.$message.success('Submit successfully')
        let orderId = res.data.id
        comfirmOrder({
          orderId,
          payMethod: ['','2','1','1'][this.selectIndex],
          urlRedirect: false
        }).then(res=>{
          getCounterUrl({
            orderId
          }).then(res2=>{
            setTimeout(() => {
              this.$nextTick(()=>{
                window.open(res2.data.counterUrl, '_blank')
              })
            }, 500);
          })
        })
      })
    },
    // 购买最大值限制
    countSet(index){
        this.tableData[index].count = this.tableData[index].count.replace(/[^\d]/g, '')
        this.tableData[index].count = this.tableData[index].count > 10 ? 10 : this.tableData[index].count 
    },
    // 增加/减少产品数量
    resetCountFunc(type, index) {
      if (type === "add") {
        this.tableData[index].count++;
      }
      if (type === "minus") {
        this.tableData[index].count--;
      }
    },
    // 判断邮箱
    verifyEmail() {
      if (this.connectForm.email) {
        let reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        this.emailStatus = reg.test(this.connectForm.email)
          ? "success"
          : "error";
      } else {
        this.emailStatus = "empty";
      }
    },
    // 检查手机号
    verifyPhone() {
      if (this.connectForm.mobile) {
        let reg = /^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/;
        this.phoneStatus = reg.test(this.connectForm.mobile)
          ? "success"
          : "error";
      } else {
        this.phoneStatus = "empty";
      }
    },
    // 关闭在线订单弹窗
    closeOrderOnline(){
        this.$emit('closeOnline')
    },
    // 选择支付方式
    payClick(item,i) {
      this.selectIndex = i
    }
  },
};
</script>

<style scoped lang="scss">
.order-online {
  position: fixed;
  left: 180px;
  top: 6%;
  z-index: 2000;
  width: 1560px;
  height: 88%;
  border: 1px solid #a7a7a7;
  border-radius: 12px;
  .order-header {
    padding: 0 19px 0 31px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 55px;
    color: #fff;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    // background: rgba(154, 154, 154, 0.6);
    // backdrop-filter: blur(10px);
    // -webkit-backdrop-filter: blur(10px);
    // backdrop-filter: blur(
    //   10px
    // ); /* This is the fallback for Safari that doesn't support backdrop-filter */
    img {
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
    span {
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 600;
    }
  }
  .order-body {
    padding: 2px 72px 0 72px;
    box-sizing: border-box;
    height: calc(100% - 55px);
    overflow-y: auto;
    background: #fff;
    border-radius: 0 0 12px 12px;
    // 订单信息
    .order-table {
      .order-title-img {
        width: 596px;
        height: 122px;
      }
      width: 96%;
      margin-bottom: 20px;
      .el-table {
        font-size: 22px;
        color: #000;
        font-family: PingFang SC;
        ::v-deep tr{
          background: #fafafa;
        }
        ::v-deep th{
          background: #fafafa;
        }
        ::v-deep .el-table__row td {
          border-bottom-color: #fff !important;
        }
         ::v-deep .cell{
          height: 90px;
          line-height: 90px;
        }
        .custom-outside {
          display: flex;
          margin-top: 32px;
          align-items: center;
          justify-content: center;
          .custom-count {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #ebebeb;
            width: 45%;
            border-radius: 24px;
            img {
              width: 24px;
              height: 24px;
              cursor: pointer;
            }
            input {
              width: 60px;
              border: none;
              outline: 0;
              height: 26px;
              text-align: center;
              background: transparent;
              font-size: 22px;
            }
          }
        }
      }
    }
    // 联系方式
    .order-connect {
      .order-title-img {
        width: 647px;
        height: 122px;
      }
    //   padding-bottom: 48px;
      box-sizing: border-box;
      .connect-form {
        display: flex;
        align-items: center;
        margin: 12px 0;
        .form-label {
          width: 170px;
          font-size: 22px;
          color: #000;
          font-family: PingFang SC;
          text-align: right;
          span {
            color: red;
          }
        }
        .form-value {
          :deep(.el-input__inner) {
            height: 50px !important;
            &::placeholder {
              color: #CCCCCC;
              font-size: 20px;
            }
          }
          .email-input{
            width: 724px; height: 50px
          }
          .same-input{
            width: 368px; height: 50px
          }
        }
        .form-tips {
          display: flex;
          align-items: flex-start;
          width: 942px;
          height: 96px;
          border-radius: 4px;
          border: 1px solid rgba(255, 255, 255, 0.8);
          padding: 12px 27px;
          box-sizing: border-box;
          img {
            margin: 8px 22px 0 0;
            width: 18px;
            height: 18px;
          }
          div {
            width: 960px;
            height: 68px;
            font-size: 20px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 34px;
          }
        }
        // 未输入邮箱
        .form-empty-tips {
          background: #e7f3fc;
        }
        // 正确邮箱
        .form-success-tips {
          background: #f3fbf1;
        }
        // 错误邮箱
        .form-error-tips {
          background: #ffeee7;
          height: 62px;
          div {
            width: 686px;
            height: 62px;
            font-size: 20px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 34px;
          }
        }
        .form-tips-mobile{
          height: 95px;
        }
        .sure-email {
          width: 186px;
          height: 50px;
          background: url("../../assets/zh/orderOnline/button.png") no-repeat;
          background-size: 100% 100%;
          font-size: 22px;
          color: #000;
          text-align: center;
          line-height: 50px;
          margin-left: 30px;
          cursor: pointer;
        }
        .finisn-email{
          width: 186px;
          height: 50px;
          background: #f3fbf1;
          color: #87d370;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 30px;
          img{
            width: 18px;
            height: 18px;
            margin-right: 6px;
          }
        }
        .confirmation-email{
          width: 236px;
          height: 50px;
          background: #f3fbf1;
          color: #87d370;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 30px;
          i{
            margin-right: 6px;
          }
        }
      }
    }
    // 支付方式
    .pay-result{
        .order-title-img {
          width: 596px;
          height: 122px;
        }
        .pay-way {
          width: 1416px;
          height: 574px;
          background-color: #FAFAFA;
          padding: 50px 255px;
          box-sizing: border-box;
          .pay-select {
            width: 621px;
            height: 64px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            padding-left: 65px;
            cursor: pointer;
            .pay-icon {
              width: 16px;
              height: 16px;
            }
            .pay-image {
              width: 30px;
              height: 30px;
              margin-left: 48px;
            }
            .pay-label {
              display: inline-block;
              margin-left: 16px;
              line-height: 30px;
              font-size: 22px;
              font-family: PingFangSC, PingFang SC;
            }
          }
          .pay-active {
            background: rgba(255,255,255,0.9);
            border-radius: 4px;
            border: 1px solid #108EE9;
          }
          .pay-sure {
            width: 260px;
            height: 62px;
            margin: 40px 65px;
            // background: url("../../assets/zh/orderOnline/button.png") no-repeat;
            background: #ccc;
            border-radius: 8px;
            background-size: 100% 100%;
            font-size: 22px;
            color: #000;
            text-align: center;
            line-height: 62px;
            cursor: pointer;
          }
          .pay-sure-none{
            width: 260px;
            height: 62px;
            margin: 40px 65px;
            background: url("../../assets/zh/orderOnline/button.png") no-repeat;
            background-size: 100% 100%;
            font-size: 22px;
            color: #000;
            text-align: center;
            line-height: 62px;
            cursor: pointer;
          }
          .pay-tip {
            background: #e7f3fc;
            border-radius: 4px;
            display: flex;
            box-sizing: border-box;
            padding: 14px 22px;
            &>img {
              width: 18px;
              height: 18px;
              margin-top: 8px;
            }
            &>div{
              width: 960px;
              height: 68px;
              margin-left: 22px;
              font-size: 20px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 34px;
            }
          }
        }
        .result-info{
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            background: #FAFAFA;
            height: 324px;
            margin-bottom: 76px;
            .result-img{
                margin-top: 40px;
                display: flex;
                align-items: center;
                img{
                    width: 90px;
                    height: 90px;
                    margin-right: 20px;
                }
                span{
                    font-size: 34px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    color: #000000;
                    line-height: 48px;
                }
            }
            .result-text{
                margin-top: 28px;
                width: 662px;
                height: 126px;
                div{
                    text-align: center;
                    font-size: 24px;
                    font-family: PingFangSC, PingFang SC;
                    color: #666666;
                    line-height: 42px;
                }
            }
        }
    }
    // 确认支付
    .pay-confirm{
      width: 1170px;
      display: flex;
      justify-content: flex-end;
      .get-order {
        width: 206px;
        height: 50px;
        background: url("../../assets/zh/orderOnline/button.png") no-repeat;
        background-size: 100% 100%;
        font-size: 22px;
        color: #000;
        text-align: center;
        line-height: 50px;
        cursor: pointer;
      }
    }
    
  }
}
// 媒体查询手机端高度还有定位跟pc不止
@media screen and (max-width: 450px) {
  .order-online {
    top: 120px; // pc为60
    height: 845px;
    .order-body{
      height: 790px;
    }
  }
}
</style>